define("ember-side-menu/components/side-menu", ["exports", "ember", "ember-side-menu/utils/gestures"], function (exports, _ember, _emberSideMenuUtilsGestures) {
    "use strict";

    var Component = _ember["default"].Component;
    var computed = _ember["default"].computed;
    var alias = _ember["default"].computed.alias;
    var htmlSafe = _ember["default"].String.htmlSafe;
    var get = _ember["default"].get;
    var set = _ember["default"].set;
    var $ = _ember["default"].$;
    var service = _ember["default"].inject.service;
    var _Ember$run = _ember["default"].run;
    var schedule = _Ember$run.schedule;
    var cancel = _Ember$run.cancel;
    var bind = _Ember$run.bind;
    var later = _Ember$run.later;

    var styleProps = ["shadowStyle", "positionStyle", "transitionStyle", "transformStyle"];

    exports["default"] = Component.extend({
        sideMenu: service(),

        progress: alias("sideMenu.progress"),
        isOpen: alias("sideMenu.isOpen"),
        isClosed: alias("sideMenu.isClosed"),
        isSlightlyOpen: alias("sideMenu.isSlightlyOpen"),
        isTouching: false,
        disableMenu: false,

        attributeBindings: ["style"],
        classNames: ["side-menu"],
        classNameBindings: ["isInProgress:disable-scroll"],

        side: "left",
        width: "70%",
        rootNodeSelector: "body",

        initialTapAreaWidth: 30,
        slightlyOpenWidth: 20,
        slightlyOpenAfter: 300,

        shadowStyle: computed("progress", function () {
            var progress = get(this, "progress");
            return progress === 0 ? "box-shadow: none;" : "";
        }),

        positionStyle: computed("width", "side", function () {
            var width = get(this, "width");
            var side = get(this, "side");

            if (side === "left") {
                return "width: " + width + "; right: initial; left: -" + width + ";";
            }

            return "width: " + width + "; left: initial; right: -" + width + ";";
        }),

        transitionStyle: computed("progress", function () {
            var progress = get(this, "progress");
            return progress === 0 || progress === 100 ? "transition: transform 0.2s ease-out;" : "transition: none;";
        }),

        transformStyle: computed("progress", "side", "isSlightlyOpen", function () {
            var side = get(this, "side");
            var isSlightlyOpen = get(this, "isSlightlyOpen");
            var slightlyOpenWidth = get(this, "slightlyOpenWidth");
            var direction = side === "right" ? "-" : "";
            var unit = isSlightlyOpen ? "px" : "%";
            var progress = isSlightlyOpen ? slightlyOpenWidth : get(this, "progress");

            return "transform: translateX(" + direction + progress + unit + ");";
        }),

        style: computed.apply(undefined, styleProps.concat([function () {
            var _this = this;

            var combinedStyle = styleProps.reduce(function (acc, style) {
                return acc + get(_this, style);
            }, "");

            return htmlSafe(combinedStyle);
        }])),

        _setScrollDisable: function _setScrollDisable() {
            var isClosed = get(this, "isClosed");
            var wasClosed = get(this, "wasClosed");

            if (isClosed === wasClosed) return;

            var $rootNode = $(get(this, "rootNodeSelector"));

            if (isClosed) {
                $rootNode.removeClass("disable-scroll");
            } else {
                $rootNode.addClass("disable-scroll");
            }

            set(this, "wasClosed", isClosed);
        },

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this._setupEventListeners();
            this._setupObservers();
        },

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this._removeEventListeners();
            this._removeObservers();
        },

        _setupEventListeners: function _setupEventListeners() {
            var _this2 = this;

            var $rootNode = $(get(this, "rootNodeSelector"));
            var onRootNodeTouch = bind(this, this._onRootNodeTouch);

            $rootNode.on("touchstart", onRootNodeTouch);

            schedule("afterRender", function () {
                set(_this2, "onTouchStart", onRootNodeTouch);
            });
            var onMenuScroll = function onMenuScroll() {
                if (!get(_this2, "disableMenu") && !get(_this2, "isInProgress")) {
                    set(_this2, "disableMenu", true);
                    _this2.$().one("touchend", function () {
                        set(_this2, "disableMenu", false);
                    });
                }
            };
            this.$().on("scroll", onMenuScroll);
        },

        _setupObservers: function _setupObservers() {
            this._setScrollDisable();
            this.addObserver("isClosed", this, "_setScrollDisable");
        },

        _removeObservers: function _removeObservers() {
            this.removeObserver("isClosed", this, "_setScrollDisable");
        },

        _removeEventListeners: function _removeEventListeners() {
            var onTouchStart = get(this, "onTouchStart");
            var $rootNode = $(get(this, "rootNodeSelector"));

            $rootNode.off("touchstart", onTouchStart);
        },

        _onRootNodeTouch: function _onRootNodeTouch(evt) {
            var _this3 = this;

            var runOpenMenuSlightly = undefined;
            var $rootNode = $(get(this, "rootNodeSelector"));
            var onTouchMove = bind(this, function (event) {
                if (runOpenMenuSlightly) {
                    cancel(runOpenMenuSlightly);
                }

                if (get(_this3, "disableMenu")) return;

                if (!(_this3._isTouchWithin(event, get(_this3, "slightlyOpenWidth")) && get(_this3, "isClosed"))) {
                    if (get(_this3, "isSlightlyOpen")) {
                        set(_this3, "isSlightlyOpen", false);
                    }

                    if (!get(_this3, "isInProgress") && _this3._isInitialGesture(event)) {
                        set(_this3, "isInProgress", true);
                    }

                    if (get(_this3, "isInProgress")) {
                        _this3._updateProgress(event.originalEvent.touches[0].pageX);
                    }
                }
            });
            var onTouchEnd = bind(this, function (event) {
                $rootNode.off("touchmove", onTouchMove);
                $rootNode.off("touchend", onTouchEnd);
                set(_this3, "isTouching", false);
                set(_this3, "isInProgress", false);

                if (runOpenMenuSlightly) {
                    cancel(runOpenMenuSlightly);
                }

                _this3._completeMenuTransition(event);
            });

            set(this, "isTouching", true);

            if (this._validToStartTracking(evt)) {
                set(this, "touchStartEvent", evt);
                this._setTouchOffset(evt);

                if (this._isTouchWithin(evt, get(this, "initialTapAreaWidth"))) {
                    runOpenMenuSlightly = later(function () {
                        if (get(_this3, "isClosed") && get(_this3, "isTouching")) {
                            set(_this3, "isSlightlyOpen", true);
                        }
                    }, get(this, "slightlyOpenAfter"));
                }

                $rootNode.on("touchmove", onTouchMove);
                $rootNode.on("touchend", onTouchEnd);
            }
        },

        _setTouchOffset: function _setTouchOffset(event) {
            var isOpen = get(this, "isOpen");
            var pageX = event.originalEvent.touches[0].pageX;
            var side = get(this, "side");

            if (isOpen) {
                if (side === "left") {
                    set(this, "touchOffset", Math.max(0, this.element.offsetWidth - pageX));
                } else {
                    set(this, "touchOffset", Math.max(0, this.element.offsetWidth - (window.innerWidth - pageX)));
                }
            } else {
                set(this, "touchOffset", 0);
            }
        },

        _updateProgress: function _updateProgress(touchPageX) {
            var elementWidth = this.element.offsetWidth;
            var touchOffset = get(this, "touchOffset");
            var side = get(this, "side");
            var relativeX = side === "left" ? touchPageX : window.innerWidth - touchPageX;
            var progress = Math.min((relativeX + touchOffset) / elementWidth * 100, 100);

            set(this, "progress", progress);
        },

        _completeMenuTransition: function _completeMenuTransition(event) {
            var progress = get(this, "progress");
            var touchStartEvent = get(this, "touchStartEvent");
            var side = get(this, "side");
            var gesture = (0, _emberSideMenuUtilsGestures.createGesture)(touchStartEvent, event);
            var minClosingVelocity = 0.3;
            var autoCompleteThreshold = 50;
            var isSwipingLeft = gesture.velocityX > minClosingVelocity;
            var isSwipingRight = gesture.velocityX < -minClosingVelocity;

            var isClosingMovement = side === "left" && isSwipingLeft || side === "right" && isSwipingRight;
            var isOpeningMovement = side === "left" && isSwipingRight || side === "right" && isSwipingLeft;

            if (isClosingMovement || progress < autoCompleteThreshold) {
                get(this, "sideMenu").close();
            } else if (isOpeningMovement || progress >= autoCompleteThreshold) {
                get(this, "sideMenu").open();
            }
        },

        _validToStartTracking: function _validToStartTracking(event) {
            return get(this, "isOpen") || this._isTouchWithin(event, get(this, "initialTapAreaWidth"));
        },

        _isInitialGesture: function _isInitialGesture(event) {
            var touchStartEvent = get(this, "touchStartEvent");
            var gesture = (0, _emberSideMenuUtilsGestures.createGesture)(touchStartEvent, event);
            var minTime = 10; // 10 ms minimum time of gesture
            var isMoreSwiping = Math.abs(gesture.velocityX) > Math.abs(gesture.velocityY);
            return gesture.time > minTime && isMoreSwiping;
        },

        _isTouchWithin: function _isTouchWithin(event, areaWidth) {
            var side = get(this, "side");
            var pageX = event.originalEvent.touches[0].pageX;

            return side === "left" && pageX < areaWidth || side === "right" && pageX > window.innerWidth - areaWidth;
        }
    });
});