define('ember-cli-infinite-scroll/utils/object', ['exports'], function (exports) {
  'use strict';

  function isSafe(obj) {
    var type = Ember.typeOf(obj);
    var correctType = type === 'object' || type === 'class' || type === 'instance';
    var notDestroyed = !Ember.get(obj, 'isDestroyed');
    var notDestroying = !Ember.get(obj, 'isDestroying');

    return correctType && notDestroyed && notDestroying;
  }

  function safeSet(obj, path, value) {
    if (isSafe(obj)) {
      return Ember.set(obj, path, value, true);
    }
    return;
  }

  function safeSetProperties(obj, properties) {
    if (isSafe(obj)) {
      return Ember.setProperties(obj, properties);
    }
    return;
  }

  function safeIncrementProperty(obj, path, value) {
    if (isSafe(obj)) {
      return obj.incrementProperty(path, value);
    }
    return;
  }

  exports.isSafe = isSafe;
  exports.safeSet = safeSet;
  exports.safeSetProperties = safeSetProperties;
  exports.safeIncrementProperty = safeIncrementProperty;
});