define('ember-cli-infinite-scroll/components/infinite-scroll', ['exports'], function (exports) {

  /**
   A component to trigger infinite scroll.
  
   @class InfiniteScrollComponent
   */

  'use strict';

  exports['default'] = Ember.Component.extend({

    /**
     The name of the method to trigger
      @property performInfinite
     @type { String }
     @default 'performInfinite
     */

    performInfinite: 'performInfinite',

    /**
     The distance from the bottom at which the infinite scroll will fire.
      @property triggerDistance
     @type { Number }
     @default 0
     */

    triggerDistance: 0,

    /**
     Whether or not the infinite scroll can be triggered.
      @property infiniteScrollAvailable
     @type { Boolean }
     @default true
     */

    infiniteScrollAvailable: true,

    actions: {

      /**
       Triggers the `performInfinite` method.
        @event performInfinite
       */

      performInfinite: function performInfinite() {
        this.sendAction('performInfinite');
      }
    }
  });
  /* eslint ember/closure-actions: 'warn' */
});