define('ember-cli-infinite-scroll/components/infinite-scroll-container', ['exports', 'ember-cli-infinite-scroll/mixins/infinite-scroll', 'ember-cli-infinite-scroll/utils/object'], function (exports, _emberCliInfiniteScrollMixinsInfiniteScroll, _emberCliInfiniteScrollUtilsObject) {
  'use strict';

  /**
   A component that contains infinite scrolled content.
  
   @class InfiniteScrollContainerComponent
   @uses InfiniteScrollMixin
   @uses DS.Store
   */

  exports['default'] = Ember.Component.extend(_emberCliInfiniteScrollMixinsInfiniteScroll['default'], {
    classNames: 'infinite-scroll-container',

    /**
     {{#crossLink "DS.Store"}}{{/crossLink}}
     */

    store: Ember.inject.service(),

    /**
     Will be passed into the scroll listener to be the observed element on scroll.
      @property scrollContainer
     @type { String }
     @default '.infinite-scroll-container'
     */

    scrollContainer: '.infinite-scroll-container',

    /**
     Starts the infinite query.
      @method didReceiveAttrs
     */

    didReceiveAttrs: function didReceiveAttrs() {
      this.infiniteQuery();
    },

    /**
     Record processing or anything else that needs to happen with the returned
     records.
      @method afterInfiniteQuery
     @param  newRecords { Object } the records returned in this cycle
     */

    afterInfiniteQuery: function afterInfiniteQuery(newRecords) {
      var content = newRecords && newRecords.get('content');
      if (!content) {
        content = newRecords;
      }
      var infiniteContentPropertyName = this.get('infiniteContentPropertyName');
      var model = this.get(infiniteContentPropertyName);

      if (model) {
        model.addObjects(content);
      } else {
        (0, _emberCliInfiniteScrollUtilsObject.safeSet)(this, infiniteContentPropertyName, newRecords);
      }
    }
  });
});