define('ember-local-storage/mixins/array', ['exports', 'ember-local-storage/mixins/storage', 'ember-local-storage/helpers/utils'], function (exports, _emberLocalStorageMixinsStorage, _emberLocalStorageHelpersUtils) {
  'use strict';

  var A = Ember.A,
      Mixin = Ember.Mixin,
      set = Ember.set;

  exports['default'] = Mixin.create(_emberLocalStorageMixinsStorage['default'], {
    _initialContent: A(),
    _clear: function _clear() {
      set(this, 'content', Ember.A());
    },

    replaceContent: _emberLocalStorageHelpersUtils.save,
    reset: _emberLocalStorageHelpersUtils.save
  });
});