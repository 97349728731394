define('ember-ic-you/components/ember-ic-you', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var isBlank = _ember['default'].isBlank;

  /**
   A simple component to send an action when it passes a distance from the bottom
   of a scrollable element.
  
   @class EmberICYou
   */

  exports['default'] = Component.extend({

    classNames: ['ember-ic-you'],

    /**
     The name of the action that will be sent.
     */

    crossedTheLine: 'crossedTheLine',

    /**
     True if the listener can be turned on.
      @property enabled
     @type { Boolean }
     @default true
     */

    enabled: true,

    /**
     The distance from the bottom at which aboveTheLine will be true.
      @property triggerDistance
     @type { Number }
     @default 0
     */

    triggerDistance: 0,

    /**
     Optional - the viewport element holding the content.
      @property scrollContainer
     @type {String}
     @default null
     */

    scrollContainer: null,

    /**
     Optional - the content that is currently being scrolled.
      @property scrolledContent
     @type {String}
     @default null
     */

    scrolledContent: null,

    /**
     The cached scroll container - used to remove listener if `scrollContainer` gets re-computed.
      @property cachedContainer
     @type {Object}
     @default null
     */

    cachedContainer: null,

    /**
     Selector for the viewport container. If null, the container will be the window.
      @property scrollContainer
     @type {String}
     @default null
     */

    _scrollContainer: computed('scrollContainer', function () {
      var selector = this.get('scrollContainer');
      return selector ? this.$().closest(selector) : _ember['default'].$(window);
    }),

    /**
     Selector for the content being scrolled. If null, the scrolled content will be the document.
      @property _scrolledContent
     @type {String}
     */

    _scrolledContent: computed('scrolledContent', function () {
      return this.$().closest(this.get('scrolledContent'));
    }),

    /**
     Caches the elements that will be used in each scroll cycle, sets an observer
     on `enabled` to fire `_switch`, and calls `_switch`;
      @method didRender
     */

    didRender: function didRender() {
      this.addObserver('enabled', this, '_switch');
      this._switch();
    },

    /**
     The names of the listeners the component will use, concatenated for use by
     jQuery.
      @property eventNames
     @type { String }
     */

    eventNames: computed(function () {
      var guid = guidFor(this);
      return 'scroll.' + guid + ' resize.' + guid;
    }),

    /**
     Deactivates the jQuery listeners.
      @method willDestroyElement
     */

    willDestroyElement: function willDestroyElement() {
      this.deactivateListeners(this.get('_scrollContainer'));
    },

    /**
     Initializes jQuery listeners.
      @method activateListeners
     */

    activateListeners: function activateListeners() {
      var _this = this;

      var scrollContainer = this.get('_scrollContainer');
      var eventNames = this.get('eventNames');

      this.deactivateListeners(this.get('cachedContainer'));
      this.set('cachedContainer', scrollContainer);

      scrollContainer.on(eventNames, function () {
        _this._listenerFired();
      });
    },

    /**
     Deinitializes jQuery listeners.
      @method deactivateListeners
     */

    deactivateListeners: function deactivateListeners(container) {
      if (isBlank(container)) {
        return;
      }
      this.set('cachedContainer', null);

      container.off(this.get('eventNames'));
    },

    /**
     Activates and deactivates listeners depending on if the component is `enabled`
      @method _switch
     @private
     */

    _switch: function _switch() {
      var enabled = this.get('enabled');

      if (enabled) {
        this.activateListeners();
      } else {
        this.deactivateListeners(this.get('_scrollContainer'));
      }
    },

    /**
     Measures the distance of the component from the bottom.
     Debounces `crossedTheLine` action.
      @method _listenerFired
     @private
     */

    _listenerFired: function _listenerFired() {
      var scrollContainer = this.get('_scrollContainer');
      var scrolledContent = this.get('_scrolledContent');
      var triggerDistance = this.get('triggerDistance');

      var icYouWindowPosition = this.$().offset().top;

      // If you are scrolling content that isn't the window itself, there should be a provided `scrolledContent` selector
      // for that content. The position of `ember-ic-you` would be measured relative to this scrolled content.
      var offsetFromTop = isEmpty(scrolledContent) ? icYouWindowPosition : icYouWindowPosition - scrolledContent.offset().top;
      var scrollContainerPosition = scrollContainer.scrollTop();
      var scrollContainerHeight = scrollContainer.height();

      var positionOfMe = offsetFromTop - scrollContainerPosition - scrollContainerHeight;
      var scrolledPassedTrigger = positionOfMe <= triggerDistance;

      if (scrolledPassedTrigger) {
        run.debounce(this, 'sendAction', 'crossedTheLine', scrolledPassedTrigger, 25);
      }
    }
  });
});